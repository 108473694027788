<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="m-1"><q-icon name="info" class="mr-1" />{{ translate('see_payment_panel') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="full-width row m-0 text-muted align-items-center px-4">
                    <span v-if="payment.created_at"><i class="text-muted fas fa-calendar mr-1"></i>{{ translate('sale_created_at') }}: {{ prettyDate(payment.created_at) }}</span>
                </b-list-group-item>
                <b-list-group-item v-if="payment">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <template v-if="payment.customer_id">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center">
                                <q-avatar class="shadow-2" size="35px">
                                    <img v-if="customer_image_link" class="black-and-white" :src="customer_image_link">
                                    <i v-else class="fas fa-user fa-2x"></i>
                                </q-avatar>
                            </div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('customer') }}</small><br />
                                <strong>{{ payment.buyer_name }}</strong>
                            </div>
                        </template>
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-credit-card fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('payment_method') }}</small><br />
                            <strong>{{ payment.payment_method }}</strong><br />
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item v-if="payment">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-money-bill-wave fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('payment_type') }}</small><br />
                            <strong>{{ payment.payment_type }}</strong><br />
                        </div>
                            
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-shopping-basket fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('sales_type_' + payment.sale_type ) }}</small><br />
                            <strong>{{ payment.item_name }}</strong><br />
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item v-if="payment">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('date_and_hour') }}</small><br />
                            <strong>{{ prettyDate(payment.payment_date) }}</strong>
                        </div>
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-desc fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('total_amount') }}</small><br />
                            <strong>{{ payment.amount }}<template v-if="currency"> {{ currency }}</template></strong>
                        </div>
                    </div>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side></q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('close') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    eventBus
} from '../../../main'
import deleteSale from '../forms/DeleteSale'
import revertSale from '../forms/RevertSale'
import handleOnlinePayment from '../forms/HandleOnlinePayment'
import changesHistory from '../show/ChangesHistory'

export default {
    name: 'SaleDetails',
    props: ['payment_id'],
    components: {
        changesHistory
    },
    computed: {
        sales_type_name: function () {
            var name = this.payment.sale_type + '_name'
            if (this.payment.custom_subscription_name) {
                return this.payment.custom_subscription_name
            }
            if (this.payment.subscription_name) {
                return this.payment.subscription_name
            }
            return this.payment[name]
        },
    },
    data: function() {
        return {
            no_history_message: '',
            submitted: false,
            description: null,
            obtained_date_message: '',
            obtained_date: false,
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            isHidden: true,
            customer_image_link: null,
            payment: {},
            payments: {},
        };
    },
    created: function () {
        this.getPayment()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)

        eventBus.$on('saleDetails_close', (status) => {
            if (status === 'reverted') {
                eventBus.$emit('update_sales')
                this.closePanel()
            }
        })
    },
    methods: {
        showObtainedDatePopup: function () {
            this.$refs.qDateProxy.show()
        },
        resetObtainedDatePopup: function () {
            this.submitted = false
            this.description = ''
            this.obtained_date_message = ''
            this.obtained_date = null
        },
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        getPayment: function () {
            return new Promise( resolve => {
                var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
                };

                axios.get(baseUrl + 'payments/' + this.payment_id, {
                        headers: headers
                    })
                    .then(response => {

                        this.payment = response.data.item
                        console.log('response.data.item', response.data.item)

                        resolve(this.payment);
                    })
                    .catch(function (error) {
                        resolve(null);
                    });
            })
        },
        getCustomerProfilePicture: function (customer_id) {
            var url = baseUrl + 'profile_images/link/customer/' + customer_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.customer_image_link = response.data.image_link ? baseUrl + response.data.image_link : null
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteSalePanel(id) {
            const panelInstance = this.$showPanel({
                component: deleteSale,
                props: {
                    payment_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        showRevertSalePanel() {
            const panelInstance = this.$showPanel({
                component: revertSale,
                props: {
                    payment_id: this.payment_id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },

        recheckPaymentStatus: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'online-payments/sale/' + this.payment.id, {}, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.updateSalePaymentStatus();
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },

        showHandleOnlinePaymentPanel(method) {
            const panelInstance = this.$showPanel({
                component: handleOnlinePayment,
                props: {
                    payment_id: this.payment.id,
                    method: method
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        this.updateSalePaymentStatus();
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },

        paymentMethod: function (payment_method) {
            let pm = payment_method.split('_')
            // should not happen
            if (pm.length < 2) {
                return payment_method
            }
            return this.translate(pm[0]) + '(' + this.translate(pm[1]) + ')'
        },

        closePanel() {
            this.$emit('closePanel', {});
        },

        async updateSalePaymentStatus() {
            const sale = await this.getPayment();
            eventBus.$emit('updateSaleStatus', this.payment_id, payment.payment_status);
        }

    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
