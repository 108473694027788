<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ translate('add_group') }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0 pt-2">
                        <q-input dense square color="primary" type="text" id="name" v-model="group.name" :placeholder="translate('name')" />
                        <div class="pl-3 font-weight-bold">
                            {{ translate('owners') }}*
                        </div>
                        <div class="form-content pl-3 pr-3">
                            <q-input @keyup="isTyping = true" v-model="searchQuery" id="filternameowners" class="search-input" type="text" :placeholder="translate('find_owners')" :dense="true">
                                <template v-slot:prepend>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
                            <div class="scroll_area">
                                <div class="list_wrapper-group-owners">
                                    <q-item :tag="owner.is_group_owner !== true ? 'label' : 'div'" :disabled="owner.is_group_owner === true" class="full-width list-item" clickable v-ripple v-for="owner in owners" :key="'group-owners' + owner.id">
                                        <q-item-section avatar>
                                            <q-avatar class="shadow-2">
                                                <img v-if="owner.image_link" class="black-and-white" :src="owner.image_link">
                                                <i v-else class="fas fa-user fa-1x text-muted"></i>
                                            </q-avatar>
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>{{ owner.name }}</q-item-label>
                                        </q-item-section>
                                        <q-item-section side>
                                            <q-radio v-if="owner.is_group_owner !== true" flat color="primary" emit-value v-model="selected" :val="owner.id" />
                                        </q-item-section>
                                    </q-item>
                                </div>
                                <infinite-loading :identifier="'infiniteGroupOwners-' + infOwners" slot="append" @infinite="getCustomer" />
                            </div>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerGroup">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'AddGroup',
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            infOwners: +new Date(),
            group: {},
            searchQuery: '',
            isTyping: false,
            selected: null,
            owners: [],
            page: 1,
            state: {},
        }
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        resetMainList: function () {
            this.page = 1
            this.owners = []
            this.infOwners++
        },
        getCustomer: function ($state) {
            var customerUrl = baseUrl +
                'groups/customers?page=' + this.page +
                '&items_per_page=' + this.$items_per_page +
                '&search=' + this.searchQuery
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(customerUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.owners.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        registerGroup: function () {
            var data = {
                'name': this.group.name,
                'owner_id': this.selected,
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.post(baseUrl + 'groups', data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_group_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        closePanel() {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    min-height: 35px;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }

    .q-radio {
        transform: scale(0.85);
    }
}
.form-content .scroll_area {
    height: 100%;
    max-height: 68vh;
    overflow-y: auto;
    overflow-x: hidden;
}
body .list_wrapper .q-item.row,
body .row.form-buttons {
    width: 100%;
}
</style>
