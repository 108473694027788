<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="mt-1"><q-icon name="fas fa-balance-scale" class="mr-1" /><strong>{{ translate('credits_history') }}</strong></div>
            </template>
            <template v-if="!credits_history || credits_history.length === 0">
                <q-item class="mb-3">
                    <q-item-section>{{ translate('credits_history_no_history_yet') }}</q-item-section>
                </q-item>
            </template>
            <b-list-group class="list-group-accent pt-3">
                <div class="pl-3 pr-3">
                    <!--[+] Credits history. -->
                    <q-item class="shadow-2 mb-3 full-width" v-for="(credit_history,i) in credits_history" :key="'credit-history-' + i +'-' + credit_history.id">
                        <q-item-section>
                            <div>
                                <strong class="mr-1">{{ prettyDate(credit_history.date) }}</strong> 
                                <!-- Service name -->
                                <br v-if="$q.screen.lt.sm && credit_history.service_name" />
                                <span class="text-blue-6 mr-1" v-if="credit_history.service_name"><i class="fas fa-swimmer fa-sm mr-1"></i>{{ credit_history.service_name }}</span> 
                                <!-- Location name -->
                                <br v-if="$q.screen.lt.sm && credit_history.location_name" />
                                <span class="text-indigo-6 mr-1" v-if="credit_history.location_name"><i class="fas fa-map-marker-alt fa-sm mr-1"></i>{{ credit_history.location_name }}</span> 
                            </div>
                            <q-item dense flat class="p-0 text-white" :class="($q.screen.lt.sm ? 'pl-2 ' : '') + 'bg-' + chipColor(credit_history.operation)">
                                <q-item-section avatar>
                                    <q-avatar square class="text-center">
                                        <img v-if="credit_history.customer_image_link" :src="credit_history.customer_image_link">
                                        <i v-else class="fas fa-user fa-1x text-muted"></i>
                                    </q-avatar>
                                </q-item-section>
                                <q-item-section>
                                    <div>
                                        {{ credit_history.customer_name }} 
                                        <!-- Operation text -->
                                        {{ history_operation_text(credit_history) }} 
                                        <!-- Operation date -->
                                        <template v-if="credit_history.operation_date">{{ prettyDate(credit_history.operation_date) }}</template>
                                    </div>
                                </q-item-section>
                            </q-item>
                        </q-item-section>
                    </q-item>
                </div>
                <!--[-] Credits history. -->
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side></q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('close') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'CreditsHistory',
    props: ['sale_id'],
    data: function() {
        return {
            submitted: false,
            description: null,
            obtained_date_message: '',
            obtained_date: false,
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            isHidden: true,
            customer_image_link: null,
            sale: {},
            payments: {},

            credits_history: [],
        };
    },
    created: function () {
        this.getSale()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        chipColor: function (operation) {
            let color = ''
            let operations_map = {
                'added': 'success',
                'consumed_attended': 'danger',
                'released': 'info',
                'reserved': 'dark',
                'consumed_absence_penalized': 'negative',
            }
            if (operation) {
                color = operations_map[operation]
            }
            return color
        },
        history_operation_text: function (history) {
            let text = ''
            if (this.$appSettings.lang === 'ro' && history.operation === 'added') {
                // display text before credits number for RO language.
                text += this.translate('credits_history_operation_' + history.operation)
                // credits_history_operation_added_singular
            } else {
                if (history.operation !== 'added') {
                    text += this.translate('credits_history_operation_' + history.operation)
                }
            }

            text += ' ' + history.credits + ' '
            text += history.credits === 0 || history.credits > 1 
                        ? (history.credits >= 20 ? this.translate('credits_history_operation_credits_alt') : this.translate('credits_history_operation_credits'))
                        : this.translate('credits_history_operation_credit')
            let operation_info_text = history.credits === 1 
                        ? 'credits_history_operation_' + history.operation + '_singular'
                        : 'credits_history_operation_' + history.operation
            let verb = ''
            if (history.operation.includes('consumed_')) {
                verb = history.operation.replace('consumed_', '')
                verb = this.translate('credits_history_consumed_by_' + verb)
            }
            text += verb ? ' (' + verb + ')' : ''
            if (this.$appSettings.lang === 'en' && history.operation === 'added') {
                // display text after credits number for RO language.
                text += ' ' + this.translate(operation_info_text)
            }
            return text
        },
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        getCreditsHistory: function () {
            let url = baseUrl + 'credits/history/sale/' + this.sale_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.credits_history = response.data.items.map(entity => {
                        let array = entity
                        array['customer_image_link'] = entity.customer_image_link ? baseUrl + entity.customer_image_link : null
                        return array
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                });

        },
        getSale: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(baseUrl + 'sales/' + this.sale_id, {
                    headers: headers
                })
                .then(response => {
                    this.customer_id = response.data.item.customer_id
                    this.getCreditsHistory()
                })
                .catch(function (error) {
                    // console.log(error)
                });
        },
        closePanel() {
            this.$emit('closePanel', {
                response: 'dsfd'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
.operation-text {
    max-width: calc(100% - 2rem); 
    // overflow-x: auto;
}
</style>
