<template>
<div>
    <b-card class="m-0 p-0" no-body>
        <div slot="header">
            <q-item class="p-0 d-flex justify-items-center">
                <q-item-section side class="p-0 text-dark">
                    <h3 class="m-0">{{ translate('payments_nav') }}</h3>
                </q-item-section>
                <q-space />
                <q-item-section side class="p-0 mr-3">
                    <q-btn v-if="!$q.screen.lt.sm" @click.stop.prevent="exportFileXLS" color="success" no-caps>
                        <i class="fas fa-file-export mr-1"></i> Export to XLS
                    </q-btn>
                    <q-btn dense v-if="$q.screen.lt.sm" flat round no-caps class="bg-white text-success" type="button" @click.stop.prevent="exportFileXLS">
                        <i class="fas fa-file-export"></i>
                    </q-btn>
                </q-item-section>
            </q-item>
        </div>
        <!-- [+] Filter by all_sales, customer_id, all_customers, group_id, all_groups, deleted, payment_status -->
        <q-item class="p-0" :class="$q.screen.lt.sm ? 'flex-wrap' : ''">
            <!-- <q-separator vertical /> -->
            <q-item-section side class="pl-0 col-xs-12 col-md-auto bg-light">
                <q-checkbox v-model="filter_by_date" :val="false" :size="$q.screen.lt.sm ? '1.75rem' : ''" color="primary" :label="translate('date')" />
            </q-item-section>
            <q-item-section class="pl-0 pr-0 col-xs-12 col-md-auto" :side="!$q.screen.lt.sm" v-if="!$q.screen.lt.sm && showResetFilters()">
                <q-btn class="pr-2 full-height" icon="close" no-caps dense flat color="dark" @click.stop="resetAllFilters()" :val="'reset'" :label="translate('reset')" />
            </q-item-section>
        </q-item>
        <q-item :class="$q.screen.lt.sm ? 'p-0' : ''" v-if="filter_by_customer">
            <q-item-section side>
                <q-radio color="primary" v-model="filterByWho" :val="'customer'" :label="translate('choose_customer')" />
            </q-item-section>
            <q-item-section side>
                <q-radio @input="updateAllCustomers" color="primary" v-model="filterByWho" :val="'all_customers'" :label="translate('all_customers')" />
            </q-item-section>
        </q-item>
        <q-item :class="$q.screen.lt.sm ? 'p-0' : ''" v-if="filter_by_group">
            <q-item-section side>
                <q-radio color="primary" v-model="filterByWho" :val="'group'" :label="translate('choose_group')" />
            </q-item-section>
            <q-item-section side>
                <q-radio @input="updateAllGroups" color="primary" v-model="filterByWho" :val="'all_groups'" :label="translate('all_groups')" />
            </q-item-section>
        </q-item>
        <template v-if="filterByWho === 'customer' || filterByWho === 'group'">
            <q-separator />
            <q-item class="p-0">
                <q-item-section>
                    <choose-customer @updateCustomer="updateCustomer" v-if="filterByWho === 'customer'" />
                    <choose-group @updateGroup="updateGroup" v-if="filterByWho === 'group'" />
                </q-item-section>
            </q-item>
        </template>
        <template v-if="filterByPaymentStatus">
            <q-separator />
            <q-item class="p-0">
                <q-item-section>
                    <!-- dropdown -->
                    <q-select 
                        class="mt-1 mb-1" 
                        borderless 
                        @input="updatePaymentStatus" 
                        :options="payment_status_options" 
                        map-options
                        multiple
                        :label="translate('payment_status')" 
                        v-model="all_payment_status" 
                        behavior="menu">
                        <template v-slot:prepend>
                            <q-avatar class="shadow-2 mr-1" size="38px">
                                <q-icon name="fas fa-lg fa-comment-dollar" />
                            </q-avatar>
                        </template>
                    </q-select>
                </q-item-section>
            </q-item>
        </template>
        <template v-if="filter_by_date">
            <q-separator />
            <q-item>
                <q-item-section class="col-3">
                    <div class="my-2">
                        <q-card>
                            <q-input 
                                borderless 
                                @focus="$refs.qDateProxy1.show()" 
                                @click="$refs.qDateProxy1.show()" 
                                @input="$refs.qDateProxy1.show()" light square color="primary" 
                                :label="translate('valid_from_date')" dense 
                                class="full-width m-0 cursor-pointer" 
                                id="valid_from" 
                                v-model="sale_from_date" 
                                no-error-icon
                            >
                                <template v-slot:prepend>
                                        <q-icon name="event" class="cursor-pointer pointer-events-none">
                                            <q-popup-proxy ref="qDateProxy1" transition-show="scale" transition-hide="scale">
                                                <q-date
                                                    first-day-of-week="1" 
                                                    mask="YYYY-MM-DD" 
                                                    @input="(val) => onUpdateDateValidFrom(sale_from_date)"
                                                    v-model="sale_from_date"
                                                    :options="optionsFnFrom"
                                                    />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                            </q-input>
                        </q-card>
                    </div>
                </q-item-section>
                <q-item-section class="col-3">
                    <div class="my-2">
                        <q-card>
                            <q-input 
                                borderless 
                                @focus="$refs.qDateProxy2.show()" 
                                @click="$refs.qDateProxy2.show()" 
                                @input="$refs.qDateProxy2.show()" light square color="primary" 
                                :label="translate('valid_until_date')" dense 
                                class="full-width m-0 cursor-pointer" 
                                id="valid_from" 
                                v-model="sale_until_date" 
                                no-error-icon
                            >
                                <template v-slot:prepend>
                                    <q-icon name="event" class="cursor-pointer pointer-events-none">
                                        <q-popup-proxy ref="qDateProxy2" transition-show="scale" transition-hide="scale">
                                            <q-date
                                                first-day-of-week="1" 
                                                mask="YYYY-MM-DD" 
                                                @input="(val) => onUpdateDateValidUntil(sale_until_date)"
                                                v-model="sale_until_date"
                                                :default-year-month="sale_until_date_default"
                                                :options="optionsFnUntil"/>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </q-card>
                    </div>
                </q-item-section>
            </q-item>
            
        </template>
        <q-btn v-if="$q.screen.lt.sm && showResetFilters()" icon="close" no-caps dense flat color="dark" @click.stop="resetAllFilters()" :val="'reset'" :label="translate('reset')" />
        <!-- [-] Filter by all_sales, customer_id, all_customers, group_id, all_groups, deleted, payment_status -->
    </b-card>
    <div class="list-group mb-3">
        <b-list-group-item id="salesListHeader" class="flex-column align-items-start vertical-margin mobile-hidden">
            <div class="d-flex w-100 justify-content-between align-items-center">
                <b-row class="align-items-center">
                    <b-col class="orderby-hover" @click.stop="setOrderBy('sale_date')" md="3">
                        <q-item class="p-0 min-height-0">
                            <q-item-section class="p-0">
                                <small :class="orderBy && orderBy === 'sale_date' ? 'text-blue-6'  : 'text-primary'" class="cursor-pointer display-block">{{ translate('date') }}</small>
                            </q-item-section>
                            <q-item-section side v-if="orderBy" class="text-blue-6 p-0">
                                <i class="fa fa-arrow-up" :title="translate('ascending')" v-if="orderBy && orderBy === 'sale_date' && !orderByDirection"></i>
                                <i class="fa fa-arrow-down" :title="translate('descending')" v-if="orderBy && orderBy === 'sale_date' && orderByDirection"></i>
                            </q-item-section>
                        </q-item>
                    </b-col>
                    <b-col md="" class="text-center">
                        <small class="text-muted display-block">{{ translate('type') }}</small>
                    </b-col>
                    <b-col md="" class="orderby-hover text-center" @click.stop="setOrderBy('amount')">
                        <q-item class="p-0 min-height-0">
                            <q-item-section class="p-0">
                                <small :class="orderBy && orderBy === 'amount' ? 'text-blue-6'  : 'text-primary'" class="cursor-pointer display-block">{{ translate('sale_amount') }}</small>
                            </q-item-section>
                            <q-item-section side v-if="orderBy" class="text-blue-6 p-0">
                                <i class="fa fa-arrow-up" :title="translate('ascending')" v-if="orderBy && orderBy === 'amount' && !orderByDirection"></i>
                                <i class="fa fa-arrow-down" :title="translate('descending')" v-if="orderBy && orderBy === 'amount' && orderByDirection"></i>
                            </q-item-section>
                        </q-item>
                    </b-col>
                    <b-col md="" class="text-center">
                        <small class="text-muted display-block">{{ translate('buyer') }}</small>
                    </b-col>
                </b-row>
            </div>
        </b-list-group-item>
        <b-list-group-item v-for="(payment,i) in payments" :key="'paymentw-' + i + '-' + (i.id, payment.id)" :id="payment.id" href="#" class="list_wrapper-sales flex-column align-items-start vertical-margin p-0">
            <b-row :class="$q.screen.lt.sm ? 'm-0 pl-0 pr-0 pt-3 pb-3' : 'p-2'" @click="showPaymentDetailsPanel(payment.id)" class="align-items-center">
                <b-col md="3">
                    <q-item class="p-0 min-height-0">
                        <q-item-section class="p-0">
                            <span>
                                <small v-if="$q.screen.lt.sm" @click.stop="setOrderBy('sale_date')" :class="orderBy && orderBy === 'sale_date' ? 'text-blue-6'  : 'text-primary'" class="cursor-pointer">{{ translate('date') }}</small><br />
                                <strong>{{ prettyDate(payment.payment_date) }}</strong>
                            </span>
                        </q-item-section>
                        <q-item-section side top v-if="orderBy && $q.screen.lt.sm" class="text-blue-6 p-0">
                            <i class="fa fa-arrow-up" :title="translate('ascending')" v-if="orderBy && orderBy === 'sale_date' && !orderByDirection"></i>
                            <i class="fa fa-arrow-down" :title="translate('descending')" v-if="orderBy && orderBy === 'sale_date' && orderByDirection"></i>
                        </q-item-section>
                    </q-item>
                </b-col>
                <b-col md="" class="text-center text-xs-left">
                    <small class="text-muted display-block">{{ translate('sales_type_' + payment.item_type) }}</small>
                    <strong>{{ payment.item_name }}</strong>
                </b-col>
                <b-col md="" class="text-center text-xs-left">
                    <q-item class="p-0 min-height-0">
                        <q-item-section class="p-0">
                            <span>
                                <small v-if="$q.screen.lt.sm" @click.stop="setOrderBy('amount')" :class="orderBy && orderBy === 'amount' ? 'text-blue-6'  : 'text-primary'" class="cursor-pointer">{{ translate('sale_amount') }}</small><br />
                                <strong>{{ payment.amount }}<template v-if="currency"> {{ currency }}</template></strong>
                            </span>
                        </q-item-section>
                        <q-item-section side top v-if="orderBy && $q.screen.lt.sm" class="text-blue-6 p-0">
                            <i class="fa fa-arrow-up" :title="translate('ascending')" v-if="orderBy && orderBy === 'amount' && !orderByDirection"></i>
                            <i class="fa fa-arrow-down" :title="translate('descending')" v-if="orderBy && orderBy === 'amount' && orderByDirection"></i>
                        </q-item-section>
                    </q-item>
                </b-col>
                <b-col md="" class="text-center text-xs-left">
                    <small class="text-muted display-block">{{ translate(payment.buyer_type) }}</small>
                    <strong>{{ payment.buyer_name }}</strong>
                </b-col>
            </b-row>
        </b-list-group-item>
        <infinite-loading :identifier="'inf-sales-'+infSales" slot="append" @infinite="getPayments" />
    </div>
</div>
</template>

<script>
import _ from 'lodash'
import {
    eventBus
} from '../../main'
import addSale from './forms/AddSale'
import creditsHistory from './show/CreditsHistory'
import addPayment from './forms/AddSalePayment'
import SaleDetails from './show/SaleDetails'
import PaymentDetails from './show/ShowPaymentDetails'
import ChooseCustomer from './forms/ChooseCustomer'
import ChooseGroup from './forms/ChooseGroup'

export default {
    name: 'SalesWorker',
    components: {
        'choose-customer': ChooseCustomer,
        'choose-group': ChooseGroup,
    },
    data: function () {
        return {
            filter_by_customer: false,
            filter_by_group: false,
            filterByPaymentStatus: false,
            filter_by_date: false,
            sale_from_date: null,
            sale_until_date: null,
            sale_until_date_default: this.getTodayDefaulYearMonth,
            payment_status: null,
            all_payment_status: null,
            buyer_type: '',
            currency: currency,
            orderBy: null,
            orderByDirection: null,
            filterBy: 'all',
            filterByWho: '',
            customer_id: null,
            group_id: null,
            infSales: +new Date(),
            enableButon: false,
            payments: [],
            page: 1,
            state: {},
        }
    },
    computed: {
        payment_status_options: function () {
            return [
                { value: 'pending', label: this.translate('sales_payment_status_pending')},
                { value: 'online_payment_started', label: this.translate('sales_payment_status_online_payment_started')},
                { value: 'online_pending_approval', label: this.translate('sales_payment_status_online_pending_approval')},
                { value: 'online_approved', label: this.translate('sales_payment_status_online_approved')},
                { value: 'unpaid', label: this.translate('sales_payment_status_unpaid') },
                { value: 'partial', label: this.translate('sales_payment_status_partial') },
                { value: 'complete', label: this.translate('sales_payment_status_complete') }
            ]
        },
        typeDisable: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableButon;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableButon;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableButon;
            }
        },
        getTodayDefaulYearMonth: function () {
            const month = +new Date().getMonth()+1
            let today = new Date().getFullYear() + '/'
            today += month <= 9 ? '0' + month : month
            return today
        }
    },
    mounted: function () {

        eventBus.$on('updateSaleStatus', (saleId, status) => {
            this.updateSalePaymentStatus(saleId, status)
        })

        eventBus.$on('update_sales', () => {
            this.resetMainList()
        })
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false;
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.getPayments(this.state)
            }
        }
    },
    methods: {
        saleCanBePaid: function (sale) {
            return  sale.creator_type == 'worker' && ['pending', 'unpaid', 'partial'].includes(sale.payment_status)
        },
        updateSalePaymentStatus: function (id, status) {
            const index = this.payments.findIndex((obj => obj.id === id))
            this.$set(this.payments[index], 'payment_status', status)
        },
        setOrderBy: function (field) {
            this.orderBy = field
            if (this.orderByDirection === null) {
                this.orderByDirection = ':desc'
            } else if (this.orderByDirection === ':desc') {
                this.orderByDirection = null
            }
            this.resetMainList()
        },
        updateCustomer: function (customer) {
            if (customer) {
                this.customer_id = customer.id
                this.group_id = null
                this.resetMainList()

                if (customer.id === 0) {
                    this.resetFilters()
                }
            }
        },
        updateAllCustomers: function () {
            this.customer_id = null
            this.group_id = null
            this.buyer_type = 'customer'
            this.resetMainList()
        },
        updateGroup: function (group) {
            if (group) {
                this.group_id = group.id
                this.customer_id = null
                this.resetMainList()

                if (group.id === 0) {
                    this.resetFilters()
                }
            }
        },
        updateAllGroups: function () {
            this.customer_id = null
            this.group_id = null
            this.buyer_type = 'group'
            this.resetMainList()
        },
        updatePaymentStatus: function () {
            this.payment_status = this.all_payment_status.reduce((acc, status) => (
                {...acc, value: `${acc.value ? acc.value + ',' : ''}${status.value}`}
            ), {})

            this.resetMainList()
        },
        optionsFnFrom (date) {
            const thisDate = new Date(date).getTime()
            const today = new Date().getTime()

            if (thisDate >= today) {
                return false
            } else {
                return true
            }
        },
        optionsFnUntil (date) {
            const startDate = new Date(this.sale_from_date).getTime()
            const thisDate = new Date(date).getTime()
            const today = new Date().getTime()

            if (thisDate <= startDate || thisDate >= today) {
                return false
            } else {
                return true
            }
        },
        onUpdateDateValidFrom: function (newValue) {
            this.sale_from_date = newValue
            this.sale_until_date = null

            const month = +new Date(newValue).getMonth() + 1
            this.sale_until_date_default = new Date(newValue).getFullYear() + '/'
            this.sale_until_date_default += month <= 9 ? '0' + month : month

            this.$nextTick(function () {
                this.$refs.qDateProxy1.hide()
            })
        },
        onUpdateDateValidUntil: function (newValue) {
            this.sale_until_date = newValue

            this.$nextTick(function () {
                this.$refs.qDateProxy2.hide()
                this.sale_from_date && this.resetMainList()
            })
        },
        showResetFilters: function () {
            return ( this.filter_by_date || this.filter_by_customer || this.filter_by_group || this.filterByPaymentStatus === true || this.filterBy === 'deleted') ||
                   (( this.filter_by_date || this.filter_by_customer || this.filter_by_group || this.filterByPaymentStatus === true) && this.filterBy === 'all')
        },
        resetFilters: function () {
            this.filterBy = 'all'
            this.filterByWho = null
        },
        resetAllFilters: function () {
            this.group_id = null
            this.customer_id = null
            this.sale_from_date = null
            this.sale_until_date = null
            this.sale_until_date_default = this.getTodayDefaulYearMonth
            this.filter_by_customer = false
            this.filter_by_group = false
            this.filter_by_date = false
            this.buyer_type = ''
            this.filterByPaymentStatus = false
            this.payment_status = null
            this.resetFilters()
            this.resetMainList()
        },
        updateAll: function () {
            this.group_id = null
            this.customer_id = null

            this.resetMainList()
        },
        resetMainList: function () {
            this.page = 1
            this.payments = []
            this.infSales++
        },
        getPayments: function ($state) {
            var paymentUrl = baseUrl + 'payments?page=' + this.page + '&items_per_page=' + this.$items_per_page +
                (this.sale_from_date && this.sale_until_date ? '&from_date=' + this.sale_from_date + '&until_date=' + this.sale_until_date : '')

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(paymentUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.payments.push(...response.data.items)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },

        showPaymentDetailsPanel(id) {
            const panelInstance = this.$showPanel({
                component: PaymentDetails,
                props: {
                    payment_id: id
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        exportFileXLS() {
            var paymentUrl = baseUrl + 'payments/xls?' +
                (this.sale_from_date && this.sale_until_date ? '&from_date=' + this.sale_from_date + '&until_date=' + this.sale_until_date : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(paymentUrl, {
                    headers: headers,
                    responseType: 'blob'
                })
                .then(response => {
                    let element = document.createElement('a')
                    
                    const url = URL.createObjectURL(response.data)
                    
                    element.setAttribute('href', url)
                    element.setAttribute('download', 'Payments.xls');

                    element.style.display = 'none';

                    element.click();

                    element.remove()
                })
                .catch(function (error) {
                    // console.log(error)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.orderby-hover {
    color: var(--q-color-primary) !important;

    &:hover [class*="text-"] {
        color: var(--q-color-primary) !important;
    }
}
</style>
