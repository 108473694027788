<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ translate('add_payment') }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="pt-2 pb-2">
                    <template v-if="sale_info">
                        <span><i class="text-muted fas fa-calendar mr-1"></i>{{ translate('add_sale_payment_sale_date') }}: {{ prettyDate(sale.sale_date) }}</span>
                        <div class="full-width row m-0 text-muted align-items-center">
                            <div class="col-5 col-md-auto pl-0 text-center"><i class="fas fa-credit-card fa-2x text-primary"></i></div>
                            <div class="col-7 col-md info-card">
                                <small>{{ translate('sales_type_' + sale.sale_type) }}</small><br />
                                <strong v-if="sale.purchased_item_name" class="text-primary">{{ sale.purchased_item_name }}</strong>
                            </div>
                            <template v-if="sale_info.customer_id">
                                <div class="col-5 col-md-auto pl-0 text-center"><i class="fas fa-user fa-2x text-cyan-8"></i></div>
                                <div class="col-7 col-md info-card">
                                    <small>{{ translate('customer') }}</small><br />
                                    <strong class="text-cyan-8">{{ sale_info.customer_name }}</strong>
                                </div>
                            </template>
                            <template v-if="sale_info.group_id">
                                <div class="col-5 col-md-auto pl-0 text-center"><i class="fas fa-group fa-2x text-cyan-8"></i></div>
                                <div class="col-7 col-md info-card">
                                    <small>{{ translate('group') }}</small><br />
                                    <strong class="text-cyan-8">{{ sale_info.group_name }}</strong>
                                </div>
                            </template>
                            <div class="col-5 col-md info-card">
                                {{ translate('total_amount') }}<br /><strong>{{ sale_info.amount }}<template v-if="currency"> {{ currency }}</template></strong>
                            </div>
                            <div class="col-7 col-md info-card">
                                {{ translate('payment_remaining_amount') }}<br /><strong>{{ sale_info.amount - sale_info.paid }}<template v-if="currency"> {{ currency }}</template></strong>
                            </div>
                        </div>
                    </template>
                </b-list-group-item>
                <b-form @submit.prevent>
                    <div class="p-0">
                        <q-input color="primary" square dense :label="translate('paid_amount')" @wheel="$event.target.blur()" type="number" step="0.01" id="paid_amount" v-model.number="sales.amount" :placeholder="translate('enter_paid_amount')" />
                    </div>
                    <div class="p-3">
                        <!-- payment_method -->
                        {{ translate('the_payment_method') }}
                        <q-btn-toggle vertical class="p-0 m-0" dense flat no-caps v-model="payment_method" color="dark" toggle-color="primary" :options="[
                            {value: 'frontdesk_cash', slot: 'frontdesk_cash'},
                            {value: 'frontdesk_card', slot: 'frontdesk_card'}
                        ]">
                            <template v-slot:frontdesk_cash>
                                <q-icon name="fas fa-money" class="mr-2" />
                                <span class="font-weight-normal">{{ translate('cash_payment_method') }}</span>
                            </template>
                            <template v-slot:frontdesk_card>
                                <q-icon name="fas fa-credit-card" class="mr-2" />
                                <span class="font-weight-normal">{{ translate('card_payment_method') }}</span>
                            </template>
                        </q-btn-toggle>
                    </div>
                    <div class="p-0">
                        <q-input class="m-0" :label="translate('description')" :placeholder="translate('enter_description')" v-model="sale.notes" square dense color="primary" type="textarea" rows="2" />
                    </div>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="button" no-caps color="info" @click="registerPayment">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'AddSalePayment',
    props: ['sale_id', 'sale'],
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            sale_info: {},
            sales: {},
            payment_method: ''
        };
    },
    created: function () {
        this.getPaymentInfo()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        getPaymentInfo: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(baseUrl + 'sales/' + this.sale_id, {
                    headers: headers
                })
                .then(response => {
                    this.sale_info = response.data.item;
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        registerPayment: function () {
            var paymentUrl = baseUrl + 'payments'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };
            var data = {
                'amount': this.sales.amount,
                'sale_id': this.sale_id,
                'notes': this.sales.notes,
                'payment_method': this.payment_method
            }

            axios.post(paymentUrl, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_sale_payment_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })
                });
        },
        closePanel() {
            this.$emit('closePanel');
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}

body .search-input {
    margin-left: -15px !important;
    margin-right: -15px !important;
    width: calc(100% + 30px);
}

.form-content {
    max-height: 84vh;
    overflow-y: auto;
    overflow-x: hidden;
}

body .list_wrapper .q-item.row {
    width: 100%;
}

.info-card {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    // border-left: 1px solid rgba(0, 0, 0, .15);
    background: #fff;
}
</style>
