<template>
<div>
    <b-list-group-item @click.stop.prevent="toggleChangesHistory" class="font-weight-bold small cursor-pointer">
        <span class="text-uppercase"><i class="fas fa-history"></i> {{ translate('heading_changes_history') }}</span>
        <div class="mt-1" v-if="!isHiddenChangesHistory && no_history_message">{{ no_history_message }}</div>
    </b-list-group-item>
    <b-list-group-item v-if="!isHiddenChangesHistory" class="p-0">
        <!--[+] Changes history. -->
        <q-item :class="i%2===0 ? 'bg-light' : ''" :dense="$q.screen.lt.sm" class="full-width m-0" v-for="(change_history,i) in changes_history" :key="'credit-history-' + i +'-' + change_history.id">
            <q-item-section>
                <q-item dense class="p-0">
                    <q-item-section v-if="change_history.created_at">
                        <small><i class="fa fa-calendar mr-1"></i>{{ translate('created_at') }}</small>
                        {{ prettyDate(change_history.created_at) }}
                    </q-item-section>
                    <q-item-section v-if="change_history.creator_name">
                        <small><i class="fa fa-user mr-1"></i>{{ translate('changes_history_creator_name') }}</small>
                        {{ change_history.creator_name }}
                    </q-item-section>
                </q-item>
                <q-item dense class="p-0">
                    <q-item-section v-if="change_history.new_valid_until">
                        <small><i class="fa fa-calendar mr-1"></i>{{ translate('new_valid_until') }}</small>
                        {{ prettyDate(change_history.new_valid_until) }}
                    </q-item-section>
                    <q-item-section v-if="change_history.old_valid_until">
                        <small><i class="fa fa-calendar mr-1"></i>{{ translate('old_valid_until') }}</small>
                        {{ prettyDate(change_history.old_valid_until) }}
                    </q-item-section>
                </q-item>
                <q-item dense class="p-0" v-if="change_history.description">
                    <q-item-section>
                        <small>
                            <q-icon name="comment" class="mr-1" size="1rem" />{{ translate('description') }}</small>
                        {{ change_history.description }}
                    </q-item-section>
                </q-item>
            </q-item-section>
        </q-item>
        <!--[-] Changes history. -->
    </b-list-group-item>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'ChangesHistory',
    props: ['sale_id'],
    data: function () {
        return {
            submitted: false,
            description: null,
            obtained_date_message: '',
            obtained_date: false,
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,
            customer_image_link: null,
            sale: {},
            payments: {},

            changes_history: [],

            isHiddenChangesHistory: true,
            no_history_message: '',
            history_request: false
        };
    },
    created: function () {
        this.getSale()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        toggleChangesHistory: function () {
            this.isHiddenChangesHistory = !this.isHiddenChangesHistory
            !this.history_request ? this.getChangesHistory() : null
        },
        updateChangesHistory: function () {
            this.getChangesHistory()
        },
        getChangesHistory: function () {
            this.history_request = true;
            let url = baseUrl + 'sales/altered/sale/' + this.sale_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.changes_history = [ ...response.data.items ]
                    if (response.status === false || response.data.items.length === 0) {
                        this.no_history_message = this.translate('changes_history_no_history_yet')
                    } else {
                        this.no_history_message = '';
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                });

        },
        getSale: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(baseUrl + 'sales/' + this.sale_id, {
                    headers: headers
                })
                .then(response => {
                    this.customer_id = response.data.item.customer_id
                })
                .catch(function (error) {
                    // console.log(error)
                });
        },
        closePanel() {
            this.$emit('closePanel', {
                response: 'dsfd'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
