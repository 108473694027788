<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="pull-right">
                    <template v-if="!sale.deleted_at && sale.payment_status === 'pending'">
                        <q-btn :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeleteSalePanel(sale_id)" icon="delete" flat />
                    </template>
                </div>
                <div class="pull-right">
                    <template v-if="!sale.online_payment_service && sale.payment_status !== 'pending'">
                        <q-btn :label="!$q.screen.lt.sm ? translate('revert') : ''" no-caps dense color="danger" @click.prevent="showRevertSalePanel()" icon="history" flat />
                    </template>
                </div>
                <div class="m-1"><q-icon name="info" class="mr-1" />{{ translate('see_sale') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item v-if="sale">
                    <span v-if="sale.created_at"><i class="text-muted fas fa-calendar mr-1"></i>{{ translate('sale_created_at') }}: {{ prettyDate(sale.created_at) }}</span>
                    <div class="full-width row m-0 text-muted align-items-center">
                        <template v-if="sale.customer_id">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center">
                                <q-avatar class="shadow-2" size="35px">
                                    <img v-if="customer_image_link" class="black-and-white" :src="customer_image_link">
                                    <i v-else class="fas fa-user fa-2x"></i>
                                </q-avatar>
                            </div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('customer') }}</small><br />
                                <strong>{{ sale.customer_name }}</strong>
                            </div>
                        </template>
                        <template v-if="sale.group_id">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-group fa-2x"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('group') }}</small><br />
                                {{ sale.group_name }}
                            </div>
                        </template>
                        <div v-if="sale.payment_status" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-money fa-2x"></i></div>
                        <div v-if="sale.payment_status" class="col-10 col-md info-card">
                            <small>{{ translate('payment_status') }}</small><br />
                            <strong>{{ translate('sales_payment_status_' + sale.payment_status) }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item v-if="sale">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-credit-card fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('sales_type_' + sale.sale_type) }}</small><br />
                            <strong>{{ sales_type_name }}</strong>
                        </div>
                        <div v-if="sale.credits" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-tasks fa-2x"></i></div>
                        <div v-if="sale.credits" class="col-10 col-md info-card">
                            <small>{{ translate('credits') }}</small><br />
                            <strong>{{ sale.credits }}</strong>
                        </div>
                        <div v-if="sale.quantity" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-desc fa-2x"></i></div>
                        <div v-if="sale.quantity" class="col-10 col-md info-card">
                            <small>{{ translate('quantity') }}</small><br />
                            <strong>{{ sale.quantity }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item v-if="sale && (sale.promo_code_id || sale.promo_standard_id)" class="list-group-item-accent-info bg-custom-lightblue">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div v-if="sale.promo_code_id" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-barcode fa-2x"></i></div>
                        <div v-if="sale.promo_code_id" class="col-10 col-md info-card">
                            <small>{{ translate('promo_code') }}</small><br />
                            <strong>{{ sale.promo_code_promotion_name }}</strong>
                        </div>
                        <div v-if="sale.promo_standard_id" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-tag fa-2x"></i></div>
                        <div v-if="sale.promo_standard_id" class="col-10 col-md info-card">
                            <small>{{ translate('standard_promo') }}</small><br />
                            <strong>{{ sale.standard_promotion_name }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
                <q-separator v-if="sale && (sale.promo_code_id || sale.promo_standard_id)" />
                <b-list-group-item v-if="sale">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-desc fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('total_amount') }}</small><br />
                            <strong>{{ sale.amount }}<template v-if="currency"> {{ currency }}</template></strong>
                        </div>
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-asc fa-2x"></i></div>
                        <div class="col-7 col-md info-card">
                            <small>{{ translate('payment_remaining_amount') }}</small><br />
                            <strong>{{ sale.amount - sale.paid }}<template v-if="currency"> {{ currency }}</template></strong>
                        </div>
                    </div>
                </b-list-group-item>
                <b-list-group-item v-if="sale && (sale.valid_from || sale.valid_until)">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div v-if="sale.valid_from" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                        <div v-if="sale.valid_from" class="col-10 col-md info-card">
                            <small>{{ translate('valid_from') }}</small><br />
                            <strong>{{ prettyDate(sale.valid_from) }}</strong>
                        </div>
                        <template v-if="sale.valid_until">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('valid_until') }}</small><br />
                                <strong>{{ prettyDate(sale.valid_until) }}</strong>
                                <q-btn v-if="!sale.deleted_at" class="ml-1" no-caps dense color="info" @input="showObtainedDatePopup">
                                    <q-icon name="edit" size="0.8rem" />{{ translate('change') }}
                                    <q-popup-proxy @show="resetObtainedDatePopup" ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                        <q-date :subtitle="translate('valid_until')" :title="obtained_date ? ' ' + prettyDate(obtained_date) : ''" first-day-of-week="1" v-model="obtained_date" mask="YYYY-MM-DD" />
                                        <q-item dense class="mt-3">
                                            <q-item-section side>
                                                <q-btn @click.stop="obtainDate()" dense no-caps class="width-auto" text-color="dark" :label="translate('sale_get_date')"></q-btn>
                                            </q-item-section>
                                            <q-item-section v-if="obtained_date_message">
                                                <small>{{ obtained_date_message }}</small>
                                            </q-item-section>
                                        </q-item>
                                        <q-item dense class="p-0 mb-3">
                                            <q-item-section class="p-0">
                                                <q-input class="m-0" :label="translate('description')" :placeholder="translate('enter_description')" v-model="description" square dense color="primary" type="textarea" rows="2" error-message="" :error="submitted === true && !description" no-error-icon />
                                            </q-item-section>
                                            <q-item-section side class="p-0">
                                                <q-btn @click="handleChangeDate" flat round color="info" :label="translate('ok')"></q-btn>
                                            </q-item-section>
                                        </q-item>
                                    </q-popup-proxy>
                                </q-btn>
                            </div>
                        </template>
                    </div>
                </b-list-group-item>
                <template v-if="sale && sale.selected_services && sale.selected_services.length">
                    <q-separator />
                    <b-list-group-item v-if="sale && (sale.valid_from || sale.valid_until)">
                        <div class="full-width row m-0 text-muted">
                            <div v-if="sale.valid_from" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-swimmer fa-2x"></i></div>
                            <div v-if="sale.valid_from" class="col-10 col-md info-card">
                                <small>{{ translate('selected_services') }}</small>
                                <q-item v-for="(service,i) in sale.selected_services" :key="'sale-selected-service-' + i + '-' + service.id" class="p-0 min-height-0 text-primary">
                                    <q-item-section>
                                        <span>
                                            {{ service.service_name }}: <span class="text-dark"><strong class="text-dark">{{ service.credits }}</strong> {{ service.credits && service.credits === 1 ? translate('credit').toLowerCase() : translate('credits').toLowerCase() }}</span>
                                        </span>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </div>
                    </b-list-group-item>
                </template>
                <b-list-group-item v-if="sale.deleted_by">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center">
                            <q-avatar class="shadow-2" size="35px">
                                <img v-if="sale.deleted_by_image_link" class="black-and-white" :src="sale.deleted_by_image_link">
                                <i v-else class="fas fa-user-cog fa-lg"></i>
                            </q-avatar>
                        </div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('sale_deleted_by') }}</small><br />
                            <strong>{{ sale.deleted_by }}</strong>
                        </div>
                        <div v-if="sale.deleted_at" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar-times fa-2x"></i></div>
                        <div v-if="sale.deleted_at" class="col-10 col-md info-card">
                            <small>{{ translate('sale_deleted_at') }}</small><br />
                            <strong>{{ sale.deleted_at.slice(0, -7) }}</strong>
                        </div>
                    </div>
                    <div class="mt-2 full-width row m-0 text-muted align-items-center">
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-question-circle fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('deletion_reason') }}</small><br />
                            <strong>{{ sale.deletion_reason ? sale.deletion_reason : '-' }}</strong>
                        </div>
                    </div>
                </b-list-group-item>

                <div v-if="sale.can_be_refreshed">
                    <b-col sm="12" md="10" class="p-3 p-md-4 bg-white">
                        <q-item-section side>
                            <q-btn size="0.75rem" no-caps color="primary" type="button" @click="recheckPaymentStatus()">
                                <i class="fas fa-sync mr-1"></i> {{ translate('recheck_status') }}
                            </q-btn>
                        </q-item-section>
                    </b-col>
                </div>

                <div v-if="sale.can_capture_payment">
                    <b-col sm="12" md="10" class="p-3 p-md-4 bg-white">
                        <q-item-section side>
                            <q-btn size="0.75rem" no-caps color="primary" type="button" @click="showHandleOnlinePaymentPanel('capture')">
                                <i class="fas fa-hand-holding-usd mr-1"></i> {{ translate('capture_payment') }}
                            </q-btn>
                        </q-item-section>
                    </b-col>
                    <b-col sm="12" md="10" class="p-3 p-md-4 bg-white">
                        <q-item-section side>
                            <q-btn size="0.75rem" no-caps color="secondary" type="button" @click="showHandleOnlinePaymentPanel('reversal')">
                                <i class="fab fa-creative-commons-nc mr-1"></i> {{ translate('reversal_payment') }}
                            </q-btn>
                        </q-item-section>
                    </b-col>
                </div>

                <b-list-group-item @click.stop.prevent="toggleHistory" class="font-weight-bold small cursor-pointer">
                    <span class="text-uppercase"><i class="fas fa-history"></i> {{ translate('payment_history') }}</span>
                    <div class="mt-1" v-if="no_history_message">{{ no_history_message }}</div>
                </b-list-group-item>
                <b-list-group-item v-if="!isHidden" class="p-0">
                    <q-item :class="i%2===0 ? 'bg-light' : ''" :dense="$q.screen.lt.sm" class="full-width m-0" v-for="(payment, i) in payments" :key="'payment-'+i+'-'+payment.id">
                        <q-item-section side class="text-dark">
                            <small><i class="fas fa-calendar mr-1"></i>{{ translate('date_and_hour') }}</small>
                            {{ prettyDate(payment.created_at) }}
                        </q-item-section>
                        <q-item-section side class="text-dark">
                            <small>{{ translate('paid_amount') }}</small>
                            <strong>{{payment.amount}}<template v-if="currency"> {{ currency }}</template></strong>
                        </q-item-section>
                        <q-item-section side class="text-dark" v-if="payment.payment_method">
                            <small>{{ translate('method') }}</small>
                            <div>
                                <q-icon size="0.65rem" name="fas fa-money" class="mr-2" /><small>{{ paymentMethod(payment.payment_method) }}</small>
                            </div>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <changes-history v-if="sale && sale.sale_type === 'subscription'" :sale_id="sale_id" ref="changesHistory"/>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side></q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('close') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    eventBus
} from '../../../main'
import deleteSale from '../forms/DeleteSale'
import revertSale from '../forms/RevertSale'
import handleOnlinePayment from '../forms/HandleOnlinePayment'
import changesHistory from '../show/ChangesHistory'

export default {
    name: 'SaleDetails',
    props: ['sale_id'],
    components: {
        changesHistory
    },
    computed: {
        sales_type_name: function () {
            var name = this.sale.sale_type + '_name'
            if (this.sale.custom_subscription_name) {
                return this.sale.custom_subscription_name
            }
            if (this.sale.subscription_name) {
                return this.sale.subscription_name
            }
            return this.sale[name]
        },
    },
    data: function() {
        return {
            no_history_message: '',
            submitted: false,
            description: null,
            obtained_date_message: '',
            obtained_date: false,
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            isHidden: true,
            customer_image_link: null,
            sale: {},
            payments: {},
        };
    },
    created: function () {
        this.getSale()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)

        eventBus.$on('saleDetails_close', (status) => {
            if (status === 'reverted') {
                eventBus.$emit('update_sales')
                this.closePanel()
            }
        })
    },
    methods: {
        showObtainedDatePopup: function () {
            this.$refs.qDateProxy.show()
        },
        resetObtainedDatePopup: function () {
            this.submitted = false
            this.description = ''
            this.obtained_date_message = ''
            this.obtained_date = null
        },
        toggleHistory: function () {
            this.isHidden = !this.isHidden
            if (this.isHidden === false) {
                this.getPaymentInfo()
            } else {
                this.payments = []
                this.no_history_message = ''
            }
        },
        handleChangeDate: function () {
            this.submitted = true
            var url = baseUrl + 'sales/altered'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {
                    sale_id: this.sale_id,
                    new_valid_until: this.obtained_date,
                    description: this.description
            }
            axios.post(url, data, {
                headers: headers
            })
            .then(response => {
                var key = response.data.key ? '_' + response.data.key : ''
                var msg = 'change_valid_until_' + response.data.msg + key
                var translated_message = this.translate(msg)
                if (response && response.data.status) {
                    this.sale.valid_until = this.obtained_date
                    this.$refs.qDateProxy.hide()
                    this.$toasted.success(translated_message, {
                        duration: this.$toasted_duration
                    })
                    this.$refs.changesHistory.updateChangesHistory()
                } else {
                    this.$toasted.error(translated_message, {
                        duration: this.$toasted_duration
                    })
                }
            })
            .catch(error => {
                var msg = error.response.data.msg
                if (msg != undefined) {
                    this.msg = msg
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })
                }
            })
        },
        obtainDate: function () {
            var url = baseUrl + 'sales/altered/hint/sale/' + this.sale_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.obtained_date = response.data.new_valid_until
                    if (response.data.new_valid_until !== null) {
                        this.obtained_date_message = this.translate('obtained_date_selected')
                    } else {
                        this.obtained_date_message = this.translate('no_obtained_date')
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        getPaymentInfo: function() {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };
            axios.get(baseUrl + 'payments/sale/' + this.sale_id, {
                    headers: headers
                })
                .then(response => {
                    this.payments = response.data.payments
                    if (response.data.status === false) {
                        this.no_history_message = this.translate('no_payment_history')
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getSale: function () {
            return new Promise( resolve => {
                var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
                };

                axios.get(baseUrl + 'sales/' + this.sale_id, {
                        headers: headers
                    })
                    .then(response => {

                        this.sale = response.data.item
                        this.sale.deleted_by_image_link = response.data.item.deleted_by_image_link ? baseUrl + response.data.item.deleted_by_image_link : null

                        if (response.data.item && response.data.item.customer_id) {
                            this.getCustomerProfilePicture(response.data.item.customer_id)
                        }

                        resolve(this.sale);
                    })
                    .catch(function (error) {
                        resolve(null);
                    });
            })
        },
        getCustomerProfilePicture: function (customer_id) {
            var url = baseUrl + 'profile_images/link/customer/' + customer_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.customer_image_link = response.data.image_link ? baseUrl + response.data.image_link : null
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteSalePanel(id) {
            const panelInstance = this.$showPanel({
                component: deleteSale,
                props: {
                    sale_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        showRevertSalePanel() {
            const panelInstance = this.$showPanel({
                component: revertSale,
                props: {
                    sale_id: this.sale_id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },

        recheckPaymentStatus: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'online-payments/sale/' + this.sale.id, {}, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.updateSalePaymentStatus();
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },

        showHandleOnlinePaymentPanel(method) {
            const panelInstance = this.$showPanel({
                component: handleOnlinePayment,
                props: {
                    sale_id: this.sale.id,
                    method: method
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        this.updateSalePaymentStatus();
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },

        paymentMethod: function (payment_method) {
            let pm = payment_method.split('_')
            // should not happen
            if (pm.length < 2) {
                return payment_method
            }
            return this.translate(pm[0]) + '(' + this.translate(pm[1]) + ')'
        },

        closePanel() {
            this.$emit('closePanel', {});
        },

        async updateSalePaymentStatus() {
            const sale = await this.getSale();
            eventBus.$emit('updateSaleStatus', this.sale_id, sale.payment_status);
        }

    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
