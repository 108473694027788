<!-- [+] Show and filter Groups. -->
<template>
<div class="fit choose-group-container">
    <q-item class="full-width" clickable @click="toggleGroups()">
        <q-item-section avatar>
            <q-avatar class="cursor-pointer shadow-2">
                <img v-if="selected_group && selected_group.image_link" :src="selected_group.image_link">
                <i v-else class="fas fa-user fa-1x text-muted"></i>
            </q-avatar>
        </q-item-section>
        <q-item-section>
            <span class="text-muted">{{translate('selected_group')}}</span>
            <h4 class="mb-0 align-self-start cursor-pointer selected-group-name">{{ selected_group && selected_group.name ? selected_group.name : translate('choose_group') }}</h4>
        </q-item-section>
        <q-item-section v-if="selected_group && selected_group.name" side>
            <q-btn stack flat @click.stop="cleanSelection()" :title="translate('cancel_selection')">
                <i class="fas fa-close"></i>
            </q-btn>
        </q-item-section>
    </q-item>
    <div v-if="showGroups" class="position-relative p-0 no-focus-style">
        <q-separator />
        <div class="row m-0 align-items-center">
            <div class="col-xs-12 text-right">
                <q-input class="search-input" @input="isTypingGroup = true" v-model="searchGroup" id="filternamegroup" type="text" :placeholder="translate('find_group')" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </div>
        </div>
        <b-card-body class="bg-light m-0 pt-0 pb-0 groups-list-container">
            <div class="card_scroll-groups">
                <div class="wrapper-groups-list">
                    <q-item class="full-width list-item" :class="selected_group && selected_group.name && group.name === selected_group.name ? 'bg-primary' : ''" tag="label" clickable v-ripple v-for="(group,i) in groups" :key="'group-' + i + '-' + group.id">
                        <q-item-section avatar>
                            <q-avatar class="shadow-2">
                                <i class="fas fa-group fa-1x text-muted"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ group.name }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-radio emit-value @input="setSelectedGroup(group.id, group.name)" size="md" flat color="primary" :id="group.id" :val="group" v-model="selected_group" />
                        </q-item-section>
                    </q-item>
                </div>
                <infinite-loading :identifier="infGroups" slot="append" @infinite="getGroups" />
            </div>
            <q-item class="p-0" style="min-height: 0;">
                <q-item-section>
                </q-item-section>
                <q-item-section side>
                    <q-btn @click.stop="toggleGroups()" flat no-caps text-color="dark" color="light" v-if="showGroups" class="pull-right">{{ translate('hide_list') }} <q-icon name="fa fa-angle-up" /></q-btn>
                </q-item-section>
            </q-item>
        </b-card-body>
    </div>
</div>
</template>
<!-- [-] Show and filter Groups. -->

<script>
import { eventBus } from '../../../main'

export default {
    name: 'ChooseGroup',
    watch: {
        searchGroup: _.debounce(function () {
            this.isTypingGroup = false
        }, 200),
        isTypingGroup: function (value) {
            if (!value) {
                this.resetMainList()
            }
        },
    },
    data: function () {
        return {
            showGroups: false,
            state: {},
            infGroups: +new Date(),
            groups: [],
            page_group: 1,
            isTypingGroup: false,
            searchGroup: '',
            group_active: 'active',
            selected_group: {
                name: ''
            },
            schedule: {},
        }
    },
    methods: {
        cleanSelection: function () {
            this.selected_group=null
            this.$emit('updateGroup', {'id': 0, 'name': ''})
            eventBus.$emit('updateGroup', {'id': 0, 'name': ''})
        },
        toggleGroups: function () {
            this.showGroups = !this.showGroups
            this.resetMainList()
        },
        hideGroups: function () {
            this.showGroups = false
        },
        resetMainList: function () {
            this.page_group = 1
            this.groups = []
            this.infGroups++
        },
        setSelectedGroup: function (group_id, group_name) {
            if (group_id) {
                this.selected_group.id = group_id
                this.selected_group.name = group_name
                this.$emit('updateGroup', {'id': group_id, 'name': group_name})
                eventBus.$emit('updateGroup', {'id': group_id, 'name': group_name})
            }
            this.showGroups = false
            return false
        },
        getGroups: function ($state) {
            var groupUrl = baseUrl +
                'groups?page=' + this.page_group +
                '&items_per_page=' + this.$items_per_page +
                '&group_status=' + this.group_active +
                '&search=' + this.searchGroup

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var options = []
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let groups = this.groups
            axios.get(groupUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        groups.push(...response.data.items.map(entity => {
                            let array = entity
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page_group++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.groups = groups
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })

        },
        groupId: function () {
            var id = 0
            if (this.selected_group && this.selected_group.id) {
                id = this.selected_group.id
            }
            return id
        },
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }

    &.selected-group {
        border-color: var(--primary)!important;
    }
}
body .search-input {
    margin-left: 15px !important;
    margin-right: 15px !important;
    max-width: calc(100% - 30px);
}
@media screen and (max-width: 767px) {
    body .search-input {
        margin-top: 10px;
        margin-left: 15px !important;
        margin-right: 0 !important;
    }
    .card_scroll-groups {
        max-height: calc(280px + 3.75rem);
    }
}
.groups-list-container {
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    z-index: 1000;
    border: 1px solid rgba(0, 0, 0, .1);
    border-top: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .1);

    .card_scroll-groups {
        overflow-x: hidden;
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>
