<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <div class="text-negative font-weight-bold pt-1">
                    <q-icon size="1rem" color="red" name="delete" />&nbsp;
                    {{translate('revert_sale')}}
                </div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="text-negative">
                    <q-item class="p-0 text-h6">
                        <q-item-section>
                            <q-item-label line="1">{{translate('revert_sale_confirmation')}}</q-item-label>
                            <q-item-label line="2">{{translate('revert_sale_confirmation_no_undo')}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <b-list-group-item>
                    <q-item class="p-0 full-width">
                        <q-item-section side>
                            <q-btn no-caps @click="revertSale" type="submit" color="negative">
                                <q-icon class="mr-1" size="1rem" name="delete" /> {{translate('cancel_schedule')}}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn no-caps @click.prevent="closePanel" type="button" color="secondary" class="text-dark">
                                <q-icon class="mr-1" size="1rem" name="cancel" /> {{translate('cancel')}}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
    import _ from 'lodash'
    import { eventBus } from '../../../main'
    
    export default {
        name: 'RevertSaleSecondConfirmation',
        props: ['sale_id', 'description'],
        data: function() {
            return {};
        },
        methods: {
            revertSale: function () {
            var url = baseUrl + 'sales/revert/' + this.sale_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.post(url, {description: this.description}, {
                    headers: headers,
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'revert_sale_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        
                        eventBus.$emit('revert_sale', 'reverted')
                        
                        this.$emit('closePanel', {
                            status: response.data.status
                        })

                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
            closePanel() {
                this.$emit('closePanel');
            }
        }
    }
</script>
