<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" />{{ translate('add_customer') }}
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent style="overflow: hidden;">
                    <b-list-group-item class="p-0 pt-2">
                        <div class="row m-0">
                            <div class="col-md-6 p-0">
                                <q-input color="primary" type="text" :label="translate('first_name')" square dense id="first_name" v-model="customer.first_name" />
                            </div>
                            <div class="col-md-6 p-0">
                                <q-input color="primary" type="text" :label="translate('last_name')" square dense id="last_name" v-model="customer.last_name" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md-6 p-0">
                                <q-input color="primary" type="text" :label="translate('email')" square dense id="email" v-model="customer.email" />
                            </div>
                            <div class="col-md-6 p-0">
                                <q-input color="primary" type="text" :label="translate('phone_number')" square dense id="phone_number" v-model="customer.phone_number" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md-6 p-0">
                                <q-input @focus="$refs.qDateProxy.show()" @click="$refs.qDateProxy.show()" @input="$refs.qDateProxy.show()" light square color="primary" :label="translate('birthday')" dense class="full-width" id="birth_date" v-model="customer.birth_date" no-error-icon :error-message="''">
                                    <template v-slot:append>
                                        <q-icon name="event" class="cursor-pointer pointer-events-none">
                                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                                <q-date first-day-of-week="1" v-model="customer.birth_date" mask="YYYY-MM-DD" @input="(val) => onUpdateDate(customer.birth_date)" />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col-md-6 p-0">
                                <q-select class="full-width" square color="primary" dense v-model="customer.gender" :options="gender_options" :placeholder="translate('gender')" map-options no-error-icon />
                            </div>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerCustomer">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'AddCustomer',
    computed: {
        gender_options: function () {
            return [{
                    value: 'f',
                    label: this.translate('gender_female')
                },
                {
                    value: 'm',
                    label: this.translate('gender_male')
                },
            ]
        }
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            customer: {},
        };
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        onUpdateDate: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qDateProxy.hide()
            })
        },
        registerCustomer: function () {
            var data = {
                'first_name': this.customer.first_name,
                'last_name': this.customer.last_name,
                'email': this.customer.email,
                'phone_number': this.customer.phone_number,
                'birth_date': this.customer.birth_date,
            }
            if (this.customer.gender && this.customer.gender.value) {
                data['gender'] = this.customer.gender.value
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'customers', data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_customer_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        });
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })
                })
        },
        closePanel() {
            this.$emit('closePanel', {
                response: 'dsfd'
            });
        }
    }
}
</script>
