<template>
    <div class="fit">
        <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
        <b-tabs borderless>
            <b-tab class="p-0">
                <template slot="title">
                    <i class="mt-1 mb-2 fas fa-history"></i>
                </template>
                <b-list-group class="list-group-accent">
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-danger bg-light font-weight-bold font-small">
                        <q-icon size="1rem" color="red" name="history" />&nbsp;
                        {{ translate('revert_sale') }}
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info p-0">
                        <q-item>
                            <q-item-section>
                                <q-item-label line="1">{{ translate('reverting_sale') }}</q-item-label>
                                <q-input v-model="delete_reason" :placeholder="translate('delete_sale_json_key_is_required_deletion_reason')"></q-input>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info ">
                        <q-item class="p-0 full-width">
                            <q-item-section side>
                                <q-btn :disable="!delete_reason" no-caps @click="showRevertSecondConfirmationPanel" type="submit" color="danger">
                                    <q-icon class="mr-1" size="1rem" name="check" /> {{ translate('yes') }}
                                </q-btn>
                            </q-item-section>
                            <q-item-section></q-item-section>
                            <q-item-section side>
                                <q-btn no-caps @click.prevent="closePanel" type="button" color="secondary" class="text-dark">
                                    <q-icon class="mr-1" size="1rem" name="cancel" /> {{ translate('no') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-list-group>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { eventBus } from '../../../main'
import RevertSaleSecondConfirmation from './RevertSaleSecondConfirmation'

export default {
    name: 'DeleteSale',
    props: ['sale_id'],
    data: function () {
        return {
            msg: null,
            delete_reason: ''
        }
    },
    mounted: function() {
        eventBus.$on('revert_sale', (status) => {
            this.closePanel(status)
        })
    },
    methods: {
        showRevertSecondConfirmationPanel() {

            const panelInstance = this.$showPanel({
                component: RevertSaleSecondConfirmation,
                props: {
                    sale_id: this.sale_id,
                    description: this.delete_reason
                }
            })
        },
        closePanel: function (status) {
            eventBus.$emit('saleDetails_close', status)
            this.$emit('closePanel')
        }
    }
}
</script>
